<template>
  <div class="specialist-rate mt-6">
    <div
      v-if="team.length"
      class="specialist-rate__content d-flex flex-column justify-space-between"
    >
      <div class="specialist-rate__inputs">
        <v-col
          cols="auto"
          class="fields col-item mb-4"
          v-for="(rate, index) in team"
          :key="index"
        >
          <v-row class="field d-flex align-center">
            <v-text-field
              class="position rounded-lg mr-4"
              outlined
              item-text="name"
              readonly
              return-object
              item-value="name"
              hide-details
              :value="rate.resource_kind.codename"
            ></v-text-field>
            <v-text-field
              class="rate rounded-lg"
              hide-details
              readonly
              placeholder="2000 тыс. / ч"
              outlined
              :value="numberWithСurrency(rate.payment)"
            ></v-text-field>
          </v-row>
        </v-col>
      </div>
    </div>
    <div v-else>Команды нет</div>
  </div>
</template>

<script>
export default {
  props: {
    team: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({}),
  methods: {
    numberWithСurrency(item) {
      return `${item} тыс. / час`;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-select {
  width: 220px;
  max-width: 220px;
}

.v-text-field {
  width: 220px;
  max-width: 220px;
}

.specialist-rate__inputs {
  display: flex;
  flex-wrap: wrap;

  & > * {
    width: calc((100% / 2) - 100px);
    margin: 0 30px 20px 0px;
  }

  .fields {
    width: 460px;

    .field {
      display: flex;
      justify-content: space-between;
      .position {
        margin: 5px 0;
      }
      .rate {
        margin: 5px 0;
      }
    }
  }
}
</style>
