<template>
  <v-dialog
    :value="isAddStaffModal"
    width="800"
    @click:outside="$emit('changeAddStaffModal', false)"
  >
    <v-card class="modal-container">
      <v-btn
        icon
        class="modal__close"
        @click="$emit('changeAddStaffModal', false)"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>

      <p class="modal__title">Добавить сотрудника</p>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        class="mt-9"
      >

        <div class="row">
          <div class="col-sm-6 py-0">
            <v-select
              :items="positions"
              label="Должность"
              outlined
              rounded
              v-model="user.position"
              item-value="name"
              item-text="name"
              class="rounded-lg"
            ></v-select>

          </div>

          <div class="col-sm-6 py-0">
            <v-text-field
              v-model="user.name"
              :rules="nameRules"
              label="Имя"
              class="rounded-lg"
              required
              outlined
              rounded
            ></v-text-field>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6 py-0">
            <DatePickerRange @changeCurrentDates="changeCurrentDates" />
          </div>

          <div class="col-sm-6 py-0">
            <v-text-field
              v-model="user.time"
              label="Часы"
              class="rounded-lg"
              required
              outlined
              rounded
            ></v-text-field>
          </div>
        </div>

        <div class="card-actions d-flex justify-center">
          <v-btn
            color="black"
            dark
            class="mt-6 modal__btn"
            x-large
            @click="save"
          >
            Сохранить
          </v-btn>
        </div>

      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import DatePickerRange from '@/components/UI/DatePickerRange.vue';

export default {
  name: 'AddStaffToProjectModal',

  data() {
    return {
      valid: true,
      dates: [],
      user: {
        name: '',
        position: '',
        time: '',
      },
    };
  },

  computed: {
    ...mapState('staff', ['positions']),
  },

  methods: {
    ...mapActions('staff', ['getPositions']),
    changeCurrentDates(val) {
      this.dates = val;
    },
    async save() {
      await this.$refs.form.validate();
      if (this.valid) {
        console.log(1);
      }
    },
  },

  components: { DatePickerRange },

  props: {
    isAddStaffModal: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
</style>
