<template>
  <div class="description d-flex pt-6">
    {{ description ? description : 'Пусто' }}
  </div>
</template>

<script>
export default {
  props: {
    description: {
      type: String,
      default: '',
      required: true,
    },
  },
};
</script>

<style>
</style>
