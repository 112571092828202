<template>
  <div class="project-orders mt-6">
    <div class="d-flex justify-space-between">
      <div class="d-inline-flex">
        <v-btn
          v-if="hasRoleAccess(permissionsRoutes.projects.createProjectTask)"
          dark
          x-large
          class="radius-root px-14 mr-4"
          @click="$router.push({name: 'CreateProjectTask', params:{id:$route.params.id}})"
        >
          Создать заказ
        </v-btn>
        <v-text-field
          label="Введите название или № заказа"
          prepend-inner-icon="mdi-magnify"
          filled
          dense
          v-model.trim="search"
          class="rounded-lg project-orders-search"
          rounded
          hide-details
          height="50"
        ></v-text-field>
      </div>
      <div class="d-flex align-center">
        <div class="project-orders-sort" :class="{ active: isSort }" @click="handleSort">
          <span class="mr-1">Сначала новые заказы</span>
          <v-icon :color="isSort ? '#212121' : '#aaaaaa'" rigth dense>mdi-sort</v-icon>
        </div>
      </div>
    </div>

    <v-expansion-panels v-if="tasks.length" class="d-flex flex-column mt-6">
      <v-expansion-panel v-for="(order, index) in projectTasks" :key="index" class="project__card">
        <v-expansion-panel-header>
          <div class="d-flex flex-column">
            <div class="d-flex align-center justify-space-between">
              <div class="d-flex">
                <h3 class="project__title mr-4">{{ order.title }}</h3>
                <div class="d-flex align-center text-light-gray fw-500">
                  <div class="status-icon status-icon_circle mr-2"
                    :class="{'status-icon_success': order.status === PROJECT_STATUSES.IS_ACTIVE}"
                  />
                  <template v-if="order.status === PROJECT_STATUSES.IS_ACTIVE">Активный</template>
                  <template v-else-if="order.status === PROJECT_STATUSES.ON_HOLD">На стопе</template>
                  <template v-else-if="order.status === PROJECT_STATUSES.COMPLETED">Завершённый</template>
                </div>
              </div>
              <div v-if="hasRoleAccess(permissionsRoutes.projects.editProjectTask)">
                <v-btn icon color="#AAAAAA" class="item__edit"
                  @click.stop="editProject(order.id)"
                >
                  <v-icon>mdi-pencil-outline</v-icon>
                </v-btn>
                <v-btn icon color="#AAAAAA" class="item__delete" @click.stop="removeOrder(order.id)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </div>
            </div>
            <div class="project-info">
              <div class="d-flex gap-16">
                <div class="project-info__item mr-4">
                  <div class="project-info__title">Менеджер</div>
                  <div v-if="project.manager_info">{{ project.manager_info.name }}</div>
                </div>
                <div class="project-info__item mr-8">
                  <div class="project-info__title">Начало заказа</div>
                  <div>{{order.date_start}}</div>
                </div>
                <div class="project-info__item mr-8">
                  <div class="project-info__title">Конец заказа</div>
                  <div>{{order.date_end}}</div>
                </div>
                <div class="project-info__item mr-8">
                  <div class="project-info__title">План бюджет</div>
                  <div>{{order.budget.toLocaleString()}} ₽</div>
                </div>
                <div class="project-info__item mr-8">
                  <div class="project-info__title">Факт. бюджет</div>
                  <div>{{order.budget_real.toLocaleString()}} ₽</div>
                </div>
                <div class="project-info__item mr-8">
                  <div class="project-info__title">Прогресс</div>
                  <div class="d-flex align-center">
                    <v-progress-circular :value="order.progress_bar"
                      :color="order.progress_bar>100?'error':'success'" size="18" class="mr-1"
                    />
                    <span :class="order.progress_bar>100?'text-error':'text-success'">
                      {{Math.ceil(order.progress_bar)}}%
                    </span>
                  </div>
                </div>
                <div class="project-info__item mr-8">
                  <div class="project-info__title text-error">Отклонение</div>
                  <div :class="{'text-error': deviation(order.progress_bar)}">
                    <template v-if="deviation(order.progress_bar)">+</template>{{ deviation(order.progress_bar) }}%
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-expansion-panel-header>

        <v-expansion-panel-content class="bg-light">
          <div class="table_order mt-5">
            <div class="header">
              <div class="resources_type">Должность</div>
              <div class="payment">
                <div class="head">Ставка</div>
                <div class="descr">в час</div>
              </div>
              <div class="abstract">ФИО</div>
              <div class="hours_per_task">
                <div class="head">Часы</div>
                <div class="descr">на заказ</div>
              </div>
              <!-- <div class="delete"></div> -->
            </div>
            <div v-for="(order,indexOrder) in order.resources" :key="indexOrder">
              <div class="elem" v-if="order.hours_per_task !== '0.00'">
                <div class="resources_type">
                  {{order.project_specialist.resource_kind.codename}}
                </div>
                <div class="payment">{{order.project_specialist.payment}}</div>
                <div class="abstract">
                  <ul>
                    <li v-for="item in order.resource_name.split(',')" :key="item">
                      {{item}}
                    </li>
                  </ul>
                </div>
                <div class="hours_per_task">{{order.hours_per_task}}</div>
              </div>
            </div>
          </div>

          <v-btn
            v-if="hasRoleAccess(permissionsRoutes.plan.nav)"
            x-large
            outlined
            class="radius-root px-14 mt-4 bg-white"
            @click="assignEmployees"
          >
            <v-icon class="mr-2">mdi-account-multiple-plus</v-icon>
            Назначить сотрудников
          </v-btn>

          <div class="description">
            <h3 class="description__header">Описание заказа</h3>
            <div class="description__text">
              {{ order.description }}
            </div>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <NoResultsTable
      :class="{ 'mt-6': !tasks.length }"
      v-if="search && projectTasks.length === 0"
      :showLabel="true"
      :labelText="'Ничего не найдено!'"
    />

    <DeleteProjectModal
      v-if="isProjectDelete"
      :data="projectTaskDeleteId"
      @changeProjectDelete="changeProjectDelete"
      @changeProjectAdd="isProjectDelete = false"
    />
  </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex';
import DeleteProjectModal from '@/views/projects/components/DeleteProjectModal.vue';
import { PROJECT_STATUSES } from '@/core/constants/projectStatuses';
import NoResultsTable from '@/components/UI/NoResultsTable.vue';

export default {
  components: { DeleteProjectModal, NoResultsTable },
  props: {
    project: {
      type: Object,
      required: true,
    },
    tasks: {
      type: Array,
      required: true,
    },
    manager: {
      type: Object,
      default: null,
    },
  },

  data: () => ({
    loading: true,
    PROJECT_STATUSES,
    isProjectDelete: false,
    projectTaskDeleteId: 'id',
    search: '',
    tempTasks: [],
    isSort: false,
  }),

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    projectTasks() {
      if (this.search === '') return this.tasks;
      const searchValue = this.search.toLowerCase();
      return this.tasks.filter((t) => t.title.toLowerCase().includes(searchValue));
    },
  },

  methods: {
    ...mapMutations('plan', ['setPlanTab']),
    ...mapActions('projects', ['editProjectTask']),
    deviation(progress) {
      const deviation = Math.ceil(progress) - 100;
      return deviation > 0 ? deviation : 0;
    },
    editProject(id) {
      this.$router.push(`/projects/${this.$route.params.id}/tasks/${id}`);
    },
    changeProjectDelete(id) {
      this.$emit('deleteProjectTask', { pid: this.$route.params.id, id });
      this.isProjectDelete = false;
    },
    removeOrder(id) {
      this.projectTaskDeleteId = id;
      this.isProjectDelete = true;
    },

    handleSort() {
      this.isSort = !this.isSort;
      this.tasks = this.tasks.reverse();
    },

    async deleteRowFromOrder(index, indexOrder) {
      this.project[index].resources.splice(indexOrder, 1);
      try {
        await this.editProjectTask({
          payload: {
            ...this.project[index],
            resources: this.project[index].resources.map((el) => ({
              ...el,
              project_specialist: el.project_specialist.id,
              codename: el.project_specialist.resource_kind.codename,
            })),
          },
          pid: this.$route.params.id,
        });
      } catch (error) {
        console.log(error);
      }
    },

    assignEmployees() {
      // Мама моя родная, но переделывать на нормальный роутинг с query параметрами это на несколько дней
      this.setPlanTab(0);
      this.$router.push({ path: '/plan' });
    },
    resourceTableData(row) {
      const data = row.map((e) => e.project_specialist);
      const table = data.map((e, i) => ({
        resources_type: e.resource_kind?.codename,
        payment: e.payment,
        abstract: 'Abstract',
        hours_per_task: row[i].hours_per_task,
        progess: '0',
        delete: 'del',
      }));
      return table;
    },
  },
};
</script>

<style lang="scss" scoped>
.project-orders {
  &-search {
    width: 388px;
  }

  &-sort {
    cursor: pointer;
    color: #aaaaaa;
    font-size: 14px;
    font-weight: 300;
    transition: .3s;

    &.active {
      color: #212121;
    }
  }
}

.v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile) > .v-expansion-panel--next-active,
.v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile) > .v-expansion-panel--active {
  border-radius: 8px !important;
}

.project__card {
  padding: 0;
  border: 1px solid #d6d6d6;
  border-radius: 8px;
  margin-top: 12px;

  &:first-child {
    margin-top: 0;
  }

  &.v-item--active, &:hover {
    border: 1px solid #212121;
  }

  .v-expansion-panel-content {
    border-radius: 0 0 8px 8px;
  }

  &::after {
    border: none;
  }

  &::before {
    box-shadow: none;
  }
}

.description {
  margin-top: 32px;
}

.description__header {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 14px;
}

.table_order {
  max-width: fit-content;
  overflow: hidden;
  border-radius: 10px;
  border: 1px solid #d6d6d6;
  cursor: auto;
  overflow-x: auto;

  .header {
    background: #f6f6f6;
    border-top: none !important;
  }
  .header,
  .elem {
    border-top: 1px solid #d6d6d6;
    width: fit-content;
    display: flex;
    & > div {
      display: flex;
      align-items: center;
      min-height: 50px;
      padding: 10px 15px;
      border-right: 1px solid #d6d6d6;
    }
    .resources_type {
      width: 140px;
      min-width: 140px;
    }
    .payment {
      width: 90px;
      min-width: 90px;
    }
    .payment,
    .hours_per_task {
      display: flex;
      padding: 0;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .head {
        height: 20px;
        font-size: 16px;
      }
      .descr {
        white-space: nowrap;
        font-size: 12px;
        color: gray;
      }
    }
    .abstract {
      width: 100%;
      min-width: 328px;
    }

    .hours_per_task {
      width: 80px;
      border-right: 0px;
      min-width: 80px;
    }

    .delete {
      display: flex;
      justify-content: center;
      width: 80px;
      min-width: 80px;
      border-right: 0px;
      i {
        cursor: pointer;
      }
    }
  }
  .elem {
    background-color: #ffffff;
    width: min-content;
  }
  .elem:hover {
    background: #f6f6f6;
  }
}
</style>
