<template>
  <Loader v-if="loading" />
  <component v-else :is="currentTabComponent"
    :projectDetail="project"
    @editProject="editProject"
    @deleteProject="delProject"
    @closeEdit="closeEdit"
  />
</template>

<script>
import { mapActions } from 'vuex';
import Loader from '@/components/UI/Loader.vue';
import ViewProject from './ViewProject.vue';
import EditProject from './EditProject.vue';

export default {
  name: 'Project',
  components: { ViewProject, EditProject, Loader },

  data: () => ({
    project: null,
    loading: true,
    currentTab: ViewProject,
    tabs: [ViewProject, EditProject],
  }),

  computed: {
    currentTabComponent() {
      return this.currentTab;
    },
  },
  methods: {
    ...mapActions('projects', ['deleteProject', 'getProject']),
    editProject(data) {
      this.project = data;
      this.currentTab = EditProject;
    },
    delProject(id) {
      this.deleteProject(id);
      this.$router.push('/projects');
    },
    closeEdit() {
      this.currentTab = ViewProject;
    },
    loadProjectInfo() {
      this.getProject(this.$route.params.id)
        .then((data) => {
          this.project = data;
        }).finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.project = null;
    this.loadProjectInfo();
    this.$emitter.$on('updateProjectInfo', () => {
      this.loadProjectInfo();
    });
  },
};
</script>
