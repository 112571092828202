<template>
  <div v-if="projectDetail" class="page">
    <v-breadcrumbs :items="breadcrumbs" />
    <section class="project-view">
      <div class="d-flex align-center justify-space-between">
        <div class="d-flex align-center">
          <div class="project__image mr-3">
            <img
              v-if="projectDetail.image_url"
              :src="projectDetail.image_url"
            />
            <span v-else>{{ projectDetail.title.substr(0, 1) }}</span>
          </div>
          <div>
            <h3 class="project__title">{{ projectDetail.title }}</h3>
            <div class="d-flex align-center text-light-gray fw-500">
              <div
                class="status-icon status-icon_circle mr-2"
                :class="{
                  'status-icon_success':
                    projectDetail.status === PROJECT_STATUSES.IS_ACTIVE,
                }"
              />
              <template
                v-if="projectDetail.status === PROJECT_STATUSES.IS_ACTIVE"
                >Активный</template
              >
              <template
                v-else-if="projectDetail.status === PROJECT_STATUSES.ON_HOLD"
                >На стопе</template
              >
              <template
                v-else-if="projectDetail.status === PROJECT_STATUSES.COMPLETED"
                >Завершённый</template
              >
            </div>
          </div>
        </div>
        <div v-if="hasRoleAccess(permissionsRoutes.projects.editProject)">
          <v-btn
            icon
            color="#AAAAAA"
            class="item__edit"
            @click="
              $router.push({
                name: 'EditProject',
                params: { id: projectDetail.id },
              })
            "
          >
            <v-icon>mdi-pencil-outline</v-icon>
          </v-btn>
          <v-btn
            icon
            color="#AAAAAA"
            class="item__delete"
            @click="isProjectDelete = true"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </div>
      </div>

      <div class="project-info">
        <div class="d-flex align-center min-w-300px">
          <div v-if="projectDetail.manager_info" class="avatar mr-3">
            <div class="avatar__status active" />
            <img
              v-if="projectDetail.manager_info.avatar"
              :src="projectDetail.manager_info.avatar"
            />
            <span v-else>{{
              projectDetail.manager_info.name.substr(0, 1)
            }}</span>
          </div>
          <div class="project-info__item">
            <div class="project-info__title">Менеджер</div>
            <div v-if="projectDetail.manager_info">
              {{ projectDetail.manager_info.name }}
            </div>
          </div>
        </div>
        <div class="d-flex gap-16 justify-space-between w-100">
          <div class="vertical-separator" />
          <div class="">
            <div class="project-info__title">Начало проекта</div>
            <div>{{ projectDetail.date_start }}</div>
          </div>
          <div class="">
            <div class="project-info__title">Конец проекта</div>
            <div>{{ projectDetail.date_end }}</div>
          </div>
          <div class="">
            <div class="project-info__title">План бюджет</div>
            <div>{{ projectDetail.budget.toLocaleString() }} ₽</div>
          </div>
          <div class="">
            <div class="project-info__title">Факт. бюджет</div>
            <div>{{ projectDetail.budget_real.toLocaleString() }} ₽</div>
          </div>
          <div class="">
            <div class="project-info__title">Прогресс</div>
            <div class="d-flex align-center">
              <v-progress-circular
                :value="projectDetail.progress"
                :color="projectDetail.progress > 100 ? 'error' : 'success'"
                size="18"
                class="mr-1"
              />
              <span
                :class="
                  projectDetail.progress > 100 ? 'text-error' : 'text-success'
                "
              >
                {{ Math.ceil(projectDetail.progress) }}%
              </span>
            </div>
          </div>
          <div class="">
            <div class="project-info__title text-error">Отклонение</div>
            <div :class="{ 'text-error': deviation }">
              <template v-if="deviation">+</template>{{ deviation }}%
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="project-tabs">
      <v-tabs class="tabs" v-model="tabInd" hide-slider>
        <v-tab
          v-for="(item, index) in itemsFiltered"
          :key="index"
          active-class="tab--light"
        >
          {{ item.text }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tabInd">
        <v-tab-item
          v-for="item in itemsFiltered"
          :key="item.value"
          :transition="false"
          :reverse-transition="false"
        >
          <v-card flat>
            <component
              v-for="(tab, ind) in currentTabComponent[tabInd]"
              :key="ind"
              :is="tab"
              :description="projectDetail.description"
              :team="projectDetail.specialists"
              :project="projectDetail"
              :tasks="projectTasks"
              :manager="projectDetail.manager_info"
              @deleteProjectTask="delProjectTask"
            />
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </section>

    <AddStaffToProjectModal
      :isAddStaffModal="isAddStaffModal"
      @changeAddStaffModal="changeAddStaffModal"
    />
    <DeleteProjectModal
      v-if="isProjectDelete"
      :data="projectDetail.id"
      @changeProjectDelete="changeProjectDelete"
      @changeProjectAdd="isProjectDelete = false"
    />
  </div>
</template>

<script>
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';
import AddStaffToProjectModal from '@/views/projects/components/AddStaffToProjectModal.vue';
import Orders from '@/views/projects/components/OrdersProject.vue';
import Team from '@/views/projects/components/TeamProject.vue';
import Description from '@/views/projects/components/DescriptionProject.vue';
import DeleteProjectModal from '@/views/projects/components/DeleteProjectModal.vue';
import { PROJECT_STATUSES } from '@/core/constants/projectStatuses';
import { permissionsRoutes } from '../../core/constants/permissions';

export default {
  components: {
    AddStaffToProjectModal,
    DeleteProjectModal,
  },
  props: {
    projectDetail: {
      type: Object,
    },
  },

  data: () => ({
    PROJECT_STATUSES,
    isAddStaffModal: false,
    isProjectDelete: false,
    tabInd: 0,
    items: [
      {
        text: 'Заказы',
        value: 0,
        permissions: permissionsRoutes.projects.taskRead,
      },
      { text: 'Команда', value: 1 },
      { text: 'Описание', value: 2 },
    ],
  }),
  computed: {
    ...mapState('projects', ['projectTasks']),
    ...mapState(['filters']),
    ...mapGetters('staff', ['getUsersList']),
    breadcrumbs() {
      return [
        {
          text: 'Проекты',
          exact: true,
          to: { name: 'Projects' },
        },
        {
          text: this.projectDetail.title,
          disabled: true,
          to: '',
        },
      ];
    },
    currentTabComponent() {
      return [[Orders], [Team], [Description]];
    },
    deviation() {
      const deviation = Math.ceil(this.projectDetail.progress) - 100;
      return deviation > 0 ? deviation : 0;
    },

    itemsFiltered() {
      return this.items
        .map((m) => {
          if (m.permissions && this.hasRoleAccess(m.permissions)) return m;
          if (!m.permissions) return m;
          return null;
        })
        .filter((f) => f);
    },
  },
  methods: {
    ...mapActions('projects', [
      'getProject',
      'deleteProject',
      'deleteProjectTask',
      'getProjectTasks',
    ]),
    ...mapMutations('projects', ['setProjectTasks']),
    ...mapActions(['getFilters']),

    async changeProjectDelete(id) {
      await this.deleteProject(id);
      this.isProjectDelete = false;
      this.$router.push({ name: 'Projects' });
    },

    changeAddStaffModal(val) {
      this.isAddStaffModal = val;
    },
    async delProjectTask(payload) {
      await this.deleteProjectTask(payload);
      await this.getProjectTasks(this.$route.params.id);
      this.$emitter.$emit('updateProjectInfo');
    },
  },
  beforeDestroy() {
    this.setProjectTasks([]);
  },
  async created() {
    await this.getFilters();
    await this.getProjectTasks(this.$route.params.id);
  },
};
</script>

<style scoped lang="scss">
@import 'src/assets/styles/variables.scss';

.page {
  display: flex;
  flex-direction: column;
}

.project-view {
  margin-top: 18px;
  padding: 40px;
  border: 1px solid #f6f6f6;
  border-radius: $border-radius-root;

  .project__title {
    font-size: 28px;
  }
  .project__image {
    width: 60px;
    height: 60px;
  }

  .project-info {
    margin-top: 32px;
  }
}

.itemHeaderActive {
}
</style>
